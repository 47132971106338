import { Container, Image, Nav, Navbar, NavItem } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from '../assets/Logo.png';
import React from "react";

export const NavigationBar = () => {
  const location = useLocation();

  const handleLogout = () => {
    window.sessionStorage.setItem("isLoggedIn", JSON.stringify(false));
    window.location.href = "/";
  }
  
  return (
    <Navbar fixed="top" bg="light" variant="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/" as={Link} to="/">
          <Image src={Logo} height="60vw" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav fill variant="pills" activeKey={location.pathname} className="mr-auto">
            <Nav.Item>
              <Nav.Link eventKey="/" as={Link} to="/">Home</Nav.Link>
            </Nav.Item>
            <NavItem>
              <Nav.Link eventKey="/enrollment" as={Link} to="/enrollment">Enrollment</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link eventKey="/rules-protocol" as={Link} to="/rules-protocol">Rules/Protocol</Nav.Link>
            </NavItem>
            {/* <NavItem>
              <Nav.Link eventKey="/graduates" as={Link} to="/graduates">Graduates</Nav.Link>
            </NavItem> */}
            <NavItem>
              <Nav.Link eventKey="/homework" as={Link} to="/homework">Homework</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link eventKey="/scoreboard" as={Link} to="/scoreboard">Scoreboard</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link eventKey="/contact-us" as={Link} to="/contact-us">Contact Us</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link onClick={handleLogout} as={Link} to="/">Logout</Nav.Link>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
