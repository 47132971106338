import { useEffect } from 'react';
import { Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Img from '../assets/graduates.jpg'

export const Graduates = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div style={{width: "50%", margin: "auto"}}>
      <br />
      <Image src={Img} width="100%" height="auto" />
    </div>
  )
}