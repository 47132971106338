import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Scoreboard = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [])
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "80%", margin: "auto", paddingTop: "10px" }}>
      <Typography
        variant="h3"
        sx={{ fontFamily: "Georgia", marginTop: "20px", marginBottom: "20px" }}
        gutterBottom
      >
        Current Leaderboard
      </Typography>
      <iframe
        src={process.env.REACT_APP_SCOREBOARD_DATA_LINK}
        width="100%"
        style={{ height: "80vh" }}
      ></iframe>
    </div>
  );
};
