import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Alert, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoginIcon from "@mui/icons-material/Login";

interface LoginProps {
  isLoggedIn: boolean;
  onLoginChange: (isLoggedIn: boolean) => void;
}

export const Login = (props: LoginProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isIncorrectPassword, setIsIncorrectPassword] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(props.isLoggedIn);

  useEffect(() => {
    setIsLoggedIn(props.isLoggedIn);
  }, [props.isLoggedIn]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  function handleLoginAttempt(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const password = (document.getElementById("password") as HTMLInputElement).value;
    if (password === process.env.REACT_APP_PASSWORD) {
      setIsLoggedIn(true);
      props.onLoginChange(true);
      setIsIncorrectPassword(false);
    } else {
      setIsIncorrectPassword(true);
      (document.getElementById("password") as HTMLInputElement).value = "";
    }
  }

  return (
    <div style={{ width: "80%", margin: "auto", marginTop: "20vh" }}>
      <Typography variant="h3" gutterBottom>
        Canada Elite Circle Login
      </Typography>
      <form onSubmit={(e) => handleLoginAttempt(e)}>
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            error={isIncorrectPassword}
            required={true}
            id="password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        {/* <br /> */}
        {isIncorrectPassword && (
          <Alert variant="outlined" severity="error" sx={{ width: "25ch", margin: "auto" }}>
            Incorrect Password
          </Alert>
        )}
        <br />
        <Button type="submit" variant="contained" size="large" endIcon={<LoginIcon />}>
          Login
        </Button>
      </form>
    </div>
  );
};
