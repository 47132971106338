import { Button, CardContent, Typography } from "@mui/material";
import { Col, Container, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

export const Home = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Row xs={12}>
          <Col xs={12} md={12}>
            <Card
              className="mx-auto"
              style={{ marginLeft: "30px", border: "none", boxShadow: "none" }}
            >
              <CardContent sx={{ textAlign: "left" }}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  color="#f69b26"
                  variant="h6"
                  gutterBottom
                >
                  WELCOME TO
                </Typography>
                <Typography
                  sx={{ fontFamily: "Georgia" }}
                  variant="h2"
                >
                  Canada Elite Circle
                </Typography>
                <Typography sx={{ fontFamily: "Helvetica" }} variant="h5">
                  Here you will check the schedule, submit your homework, and
                  view your rankings.
                </Typography>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Row className="mx-auto" xs="auto">
          <Col className="mx-auto" style={{ marginBottom: "10px" }}>
            <Button
              onClick={() => {
                handleClick("/enrollment");
              }}
              sx={{
                fontSize: "2.5vh",
                maxWidth: "30vh",
                maxHeight: "10vh",
                minWidth: "30vh",
                minHeight: "10vh",
              }}
              variant="contained"
              size="large"
            >
              ENROLL NOW
            </Button>
          </Col>
          <Col className="mx-auto" style={{ marginBottom: "10px" }}>
            <Button
              onClick={() => {
                handleClick("/rules-protocol");
              }}
              sx={{
                fontSize: "2.5vh",
                maxWidth: "30vh",
                maxHeight: "10vh",
                minWidth: "30vh",
                minHeight: "10vh",
              }}
              color="secondary"
              variant="contained"
              size="large"
            >
              Rules/Protocol
            </Button>
          </Col>
          <Col className="mx-auto" style={{ marginBottom: "10px" }}>
            <Button
              onClick={() => {
                handleClick("/scoreboard");
              }}
              sx={{
                background: "#2ac99c",
                fontSize: "2.5vh",
                maxWidth: "30vh",
                maxHeight: "10vh",
                minWidth: "30vh",
                minHeight: "10vh",
              }}
              variant="contained"
              size="large"
            >
              Scoreboard
            </Button>
          </Col>
          <Col className="mx-auto" style={{ marginBottom: "10px" }}>
            <Button
              sx={{
                background: "#ED254E",
                fontSize: "2.5vh",
                maxWidth: "30vh",
                maxHeight: "10vh",
                minWidth: "30vh",
                minHeight: "10vh",
              }}
              variant="contained"
              size="large"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeuJ8bfutWhJ0P5FVEPTQrLlmoC-9raEBn8xt2tzdzS2HCh4Q/viewform"
              target="_blank"
              rel="noreferrer noopener"
            >
              BPM Sign In
            </Button>
          </Col>
        </Row>
      </Container>
      <iframe
        src={process.env.REACT_APP_CLASS_INFO_LINK}
        frameBorder="0"
        width="100%"
        height="569"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};
