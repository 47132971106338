import { Image } from "react-bootstrap";
import Img from "../assets/enrollment.jpg";
import React, { useEffect, useState } from "react";
import JotformEmbed from "./JotformEmbed";
import { useNavigate } from "react-router-dom";

export const Enrollment = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    
    <div style={{ width: "80%", margin: "auto" }}>
      <br />
      <Image src={Img} width="70%" height="auto" />
      <br />
      <br />
      <JotformEmbed src={process.env.REACT_APP_ENROLLMENT_JOTFORM} />
    </div>
  );
};
