import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RulesProtocol = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <div>
      <Typography
        variant="h3"
        sx={{
          fontFamily: "Georgia",
          marginTop: "20px",
          marginBottom: "20px",
        }}
        gutterBottom
      >
        Rules / Protocol
      </Typography>
      <div>
        <iframe
          src={process.env.REACT_APP_RULES_PROTOCOL_LINK}
          width="100%"
          height={550}
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      </div>
    </div>
  );
};
