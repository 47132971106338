import { Card, CardContent, Typography } from "@mui/material";
import { Image } from "react-bootstrap";
import Img from "../assets/contact-us.jpg";
import QuestionImage from "../assets/question-mark.png";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ContactUs = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
    else {
      window.scrollTo(0, 0);
    }
  }, [])

  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <br />
      <Image src={Img} width="80%" />
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card sx={{ marginTop: "50px" }} variant="outlined">
          <CardContent>
            <Image src={QuestionImage} width="25%" />
            <Typography
              sx={{
                fontFamily: "Helvetica",
                marginTop: "50px",
              }}
              variant="h4"
            >
              If you have any questions or concerns, please reach out to your
              SMD.
            </Typography>
          </CardContent>
        </Card>
      </div>
      <br />
    </div>
  );
};
