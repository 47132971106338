import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import JotformEmbed from "./JotformEmbed";

export const Homework = () => {
  const [url, setUrl] = useState("");

  const handleClick = (path: string | undefined) => {
    setUrl(path as string);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [])
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "80%", margin: "auto", paddingTop: "10px" }}>
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Row className="mx-auto" xs="auto" style={{ marginBottom: "25px" }}>
          <Col className="mx-auto" style={{ marginBottom: "10px" }}>
            <Button
              style={{
                background: "#b3d1f5",
                color: "#1f497a",
                border: "2px solid #1f497a",
                fontSize: "2.5vh",
                maxWidth: "20vh",
                maxHeight: "10vh",
                minWidth: "20vh",
                minHeight: "10vh",
              }}
              size="lg"
              onClick={() => {
                handleClick(process.env.REACT_APP_RECRUIT_HW_JOTFORM);
              }}
            >
              Recruit
            </Button>
          </Col>
          <Col className="mx-auto" style={{ marginBottom: "10px" }}>
            <Button
              style={{
                color: "#566639",
                background: "#f4ffdf",
                border: "2px solid #566639",
                fontSize: "2.5vh",
                maxWidth: "20vh",
                maxHeight: "10vh",
                minWidth: "20vh",
                minHeight: "10vh",
              }}
              size="lg"
              onClick={() => {
                handleClick(process.env.REACT_APP_SALES_HW_JOTFORM);
              }}
            >
              Sales
            </Button>
          </Col>
          <Col className="mx-auto" style={{ marginBottom: "10px" }}>
            <Button
              style={{
                color: "#962d53",
                background: "#fff8fa",
                border: "2px solid #962d53",
                fontSize: "2.5vh",
                maxWidth: "20vh",
                maxHeight: "10vh",
                minWidth: "20vh",
                minHeight: "10vh",
              }}
              size="lg"
              onClick={() => {
                handleClick(process.env.REACT_APP_EVENTS_HW_JOTFORM);
              }}
            >
              Events
            </Button>
          </Col>
          <Col className="mx-auto" style={{ marginBottom: "10px" }}>
            <Button
              style={{
                color: "#962d53",
                background: "#f5d1db",
                border: "2px solid #962d53",
                fontSize: "2.5vh",
                maxWidth: "20vh",
                maxHeight: "10vh",
                minWidth: "20vh",
                minHeight: "10vh",
              }}
              size="lg"
              onClick={() => {
                handleClick(process.env.REACT_APP_BONUS_HW_JOTFORM);
              }}
            >
              Bonus
            </Button>
          </Col>
          <Col className="mx-auto" style={{ marginBottom: "10px" }}>
            <Button
              style={{
                color: "#2c3345",
                background: "#f4d092",
                border: "2px solid #2c3345",
                fontSize: "2.5vh",
                maxWidth: "20vh",
                maxHeight: "10vh",
                minWidth: "20vh",
                minHeight: "10vh",
              }}
              size="lg"
              onClick={() => {
                handleClick(process.env.REACT_APP_BOOK_SUMMARY_HW_JOTFORM);
              }}
            >
              Book Summary
            </Button>
          </Col>
        </Row>
      </Container>
      {url !== "" && <JotformEmbed src={url} />}
      {url === "" && (
        <iframe
          src={process.env.REACT_APP_HW_SLIDES}
          frameBorder="0"
          width="100%"
          height={550}
          allowFullScreen={true}
        ></iframe>
      )}
    </div>
  );
};
